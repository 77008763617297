import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { getTicketStatusBadge } from 'src/screens/passengers/utils/Passenger.utils';
import { IBranch, IDestination } from 'src/types/branch.type';
import { IPassenger } from 'src/types/passanger.type';
import { ISeat } from 'src/types/seat-map.type';
import { ITravelSchedule } from 'src/types/travel.type';
import { ITrip } from 'src/types/trip.type';
import { IVehicle, IVehicleClass } from 'src/types/vehicle.type';
import { textOrNA } from 'src/utilities/string.utils';

export const tripColumns: ColumnsType<ITrip> = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Departure Terminal',
    dataIndex: 'branch',
    render: ({ name }: IBranch) => <span>{`${name}`}</span>,
    key: 'branch',
  },
  {
    title: 'Final Destination',
    dataIndex: 'destination',
    render: ({ name }: IDestination) => <span>{`${name}`}</span>,
    key: 'destination',
  },
  {
    title: 'Maximum Capacity',
    dataIndex: 'maximumCapacity',
    render: (text: string) => text,
    key: 'maximumCapacity',
  },
  {
    title: 'Estimated Check-In Time',
    dataIndex: 'expectedCheckinTime',
    render: (text: string) => (text ? moment.utc(text).format('MMM Do YYYY, h:mm:ss a') : ''),
    key: 'expectedCheckinTime',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date: string, { time }) => moment(`${date} ${time} `).format('YYYY-MM-DD'),
  },
  {
    title: 'Time',
    dataIndex: 'time',
    render: (time: string, { date }) => moment(`${date} ${time}`).format('HH:mm A'),
    key: 'time',
  },
  {
    title: 'Vehicle',
    dataIndex: 'vehicle',
    render: (vehicle?: IVehicle) => (vehicle ? `${vehicle?.make} (${vehicle?.registeredNumber})` : 'N/A'),
    key: 'vehicle',
  },
  {
    title: 'Vehicle Number',
    dataIndex: 'virtualVehicleNumber',
    render: (virtualVehicleNumber: string) => textOrNA(virtualVehicleNumber),
    key: 'virtualVehicleNumber',
  },
  {
    title: 'Vehicle Class',
    dataIndex: 'vehicleClass',
    render: (vehicleClass: IVehicleClass) => vehicleClass.name,
    key: 'vehicleClass',
  },
  {
    title: 'Discount',
    dataIndex: 'discounts',
    render: (_, render) =>
      render.discounts!.map((discount) => (
        <span key={discount.id}>{`${discount.description} - ${discount.type}`}</span>
      )),
    key: 'discounts',
  },
];

export const tripPassengerColumns: ColumnsType<IPassenger> = [
  {
    title: 'Fullname',
    dataIndex: 'fullName',
    key: 'fullName',
    fixed: 'left',
    width: 200,
  },
  {
    title: 'Destination',
    dataIndex: 'travelSchedule',
    key: 'travelSchedule',
    render: (ts: ITravelSchedule) => ts.route?.destination?.name,
  },
  {
    title: 'Status',
    width: 120,
    render: (p: IPassenger) => getTicketStatusBadge(p.ticket!),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 180,
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Seat Number',
    dataIndex: 'seat',
    key: 'seat',
    render: (seat?: ISeat) => seat?.number,
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Next of Kin Name',
    render: (passenger: IPassenger) => `${passenger.nextKinFirstName} ${passenger.nextKinLastName}`,
  },
  {
    title: 'Next of Kin Phone',
    dataIndex: 'nextKinPhone',
    key: 'nextKinPhone',
  },
];

export const isClosed = (trip: ITrip) => {
  return !!trip.closedAt;
};
