import { Button, TimePicker, Modal, Checkbox, Form, Row, Col, Select, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import moment from 'moment';

import 'src/screens/trip-configuration/trip-config-modal/styles/tripConfigModal.styles.less';
import { IRoutesGroup } from 'src/types/route.type';

import { IVehicleClass } from 'src/types/vehicle.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITripConfiguration } from 'src/types/trip.type';
import { useGetRouteGroups } from 'src/hooks/useGetRouteGroups';
import { ICreateTripConfigParams, usePostCreateTripConfig } from 'src/hooks/usePostCreateTripConfig';
import { frequencyOptions } from 'src/screens/trip-configuration/TripConfigurationUtils';
import { useUpdateTripConfig } from 'src/hooks/useUpdateTripConfig';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { useGetDestinations } from 'src/hooks/useGetDestinations';
import { IDestination } from 'src/types/branch.type';
import { useGetVehicleClasses } from 'src/hooks/useGetVehicleClasses';
import { useGetSeatMaps } from 'src/hooks/useGetSeatMaps';
import { ISeatMap } from 'src/types/seat-map.type';
import { useGetDiscountsQuery } from 'src/graphql/queries/get-all-discounts';
import { DISCOUNT_SEARCH } from 'src/types/discount.type';
import { Discount } from 'src/graphql/generated/types';

const { Option } = Select;

export interface TripConfigProps {
  show: boolean;
  onClose: () => void;
  onTripConfigChange: (travelConfiguration: ITripConfiguration) => void;
  defaultValue?: ITripConfiguration;
}

export const TripConfigModal: React.FC<TripConfigProps> = ({ show, onClose, onTripConfigChange, defaultValue }) => {
  const [form] = Form.useForm();
  const { activeBranch } = useBranch();

  const [{ data: routeGroups, loading: loadingRouteGroups }] = useGetRouteGroups({
    params: { branchId: activeBranch?.id! },
  });
  const [{ data: vehicleClasses, loading: loadingVehicleClasses }] = useGetVehicleClasses();
  const [{ data: destinations, loading: loadingDestinations }] = useGetDestinations();
  const [createTravelConfiguration, { loading: createTripConfigLoading }] = usePostCreateTripConfig();
  const [updateTripConfig, { loading: updatedTripConfigLoading }] = useUpdateTripConfig();
  const [{ data: seatMaps, loading: loadingSeatMaps }] = useGetSeatMaps();
  const { data: discounts, loading: discountLoading } = useGetDiscountsQuery(1, 100, 100, DISCOUNT_SEARCH.ACTIVE, true);

  const { successMessage } = useMessage();

  const isLoading =
    loadingRouteGroups ||
    loadingVehicleClasses ||
    createTripConfigLoading ||
    updatedTripConfigLoading ||
    loadingDestinations ||
    loadingSeatMaps ||
    discountLoading;

  useEffect(() => {
    if (!show) {
      form.resetFields();
    }
  }, [show]);

  useEffect(() => {
    if (defaultValue) {
      const [hour, mintues, seconds] = defaultValue.time.split(':');
      form.resetFields();
      form.setFieldsValue({
        ...defaultValue,
        time: moment()
          .set('hour', parseInt(hour, 10))
          .set('minute', parseInt(mintues, 10))
          .set('second', parseInt(seconds, 10)),
      });
    }
  }, [defaultValue]);

  const routeGroupOptions = routeGroups?.map((group: IRoutesGroup) => (
    <Option key={group.id} value={group.id}>
      {`${group.name}`}
    </Option>
  ));

  const discountOptions = discounts?.findDiscounts.items?.map((discount: Discount) => (
    <Option key={discount.id} value={discount.id}>
      {`${discount.description}`}
    </Option>
  ));

  const vehicleClassOptions = vehicleClasses?.map((vehicleClass: IVehicleClass) => (
    <Option key={vehicleClass.id} value={vehicleClass.id}>
      {`${vehicleClass.name}`}
    </Option>
  ));

  const destinationOptions = destinations?.map((destination: IDestination) => (
    <Option key={destination.id} value={destination.id}>
      {`${destination.name} (${destination.code})`}
    </Option>
  ));

  const seatMapOptions = seatMaps?.map((seatMap: ISeatMap) => (
    <Option key={seatMap.id} value={seatMap.id}>
      {`${seatMap.seatsCount} Seater`}
    </Option>
  ));

  const handleSave = async (values: any) => {
    const requiredFields: ICreateTripConfigParams = {
      ...values,
      time: moment(values.time).format('HH:mm'),
      branchId: activeBranch?.id!,
    };

    const config = defaultValue
      ? await updateTripConfig({ params: { configId: defaultValue.id, ...requiredFields } })
      : await createTravelConfiguration({ params: requiredFields });

    if (config) {
      onTripConfigChange(config);
      successMessage({ content: 'Configs successfully completed' });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={572}
      title={defaultValue ? 'Update trip configuration' : 'Create trip configuration'}
      open={show}
      closable={!isLoading}
      maskClosable={isLoading}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      <Form autoComplete="on" layout="vertical" form={form} requiredMark={false} onFinish={handleSave}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="time" label="Time" rules={[{ required: true, message: 'Please select travel time' }]}>
              <TimePicker id="time" className="time-picker" suffixIcon={<DownOutlined />} format="HH:mm" use12Hours />
            </Form.Item>

            <Form.Item
              name="routeGroupId"
              label="Route group"
              rules={[{ required: true, message: 'Please select a route group' }]}
            >
              <Select id="route-group" showSearch placeholder="Select route group">
                {routeGroupOptions}
              </Select>
            </Form.Item>
            <Form.Item
              name="vehicleClassId"
              label="Vehicle class"
              rules={[{ required: true, message: 'Please select a vehicle class' }]}
            >
              <Select showSearch placeholder="Select vehicle class">
                {vehicleClassOptions}
              </Select>
            </Form.Item>

            <Form.Item
              name="destinationId"
              label="Final Destination"
              rules={[{ required: true, message: 'Please select a final destination for trips' }]}
            >
              <Select showSearch placeholder="Select final destination">
                {destinationOptions}
              </Select>
            </Form.Item>
            <Form.Item name="discountIds" label="Discounts">
              <Select
                loading={discountLoading}
                mode="multiple"
                allowClear
                id="discountIds"
                showSearch
                placeholder="Select a discount"
              >
                {discountOptions}
              </Select>
            </Form.Item>

            <Form.Item
              name="seatMapId"
              label="Seat Map"
              rules={[{ required: true, message: 'Please select a seat map to be used for the trip' }]}
            >
              <Select placeholder="Select seat map">{seatMapOptions}</Select>
            </Form.Item>
            <Form.Item
              name="frequency"
              label="Frequency"
              help="This is the number trips to be created"
              rules={[{ required: true, message: 'Please provide a frequency for this configuration' }]}
            >
              <Select showSearch placeholder="Select vehicle class">
                {frequencyOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Days"
              name="days"
              className="form-item"
              rules={[{ required: true, message: 'Pick at least one day' }]}
            >
              <Checkbox.Group>
                <Space size={15} direction="vertical">
                  <Col span={24}>
                    <Checkbox value="Mon">MONDAY</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Tue">TUESDAY</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Wed">WEDNESDAY</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Thu">THURSDAY</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Fri">FRIDAY</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Sat">SATURDAY</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="Sun">SUNDAY</Checkbox>
                  </Col>
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
