import { IPassenger } from 'src/types/passanger.type';
import { ITravelSchedule } from 'src/types/travel.type';
import { IUser } from 'src/types/user.type';
import { IWaybill } from 'src/types/waybill.type';

export enum TRANSACTION_TYPE {
  RESERVATION = 'reservation',
  CANCELLATION = 'cancellation',
  REFUND = 'refund',
  WAYBILL = 'waybill',
}

export enum TRANSACTION_STATUS {
  PENDING = 'pending',
  PROCESSED = 'processed',
  CANCELLED = 'cancelled',
  REVERSED = 'reversed',
}

export enum PAYMENT_METHODS {
  CASH = 'cash',
  POS = 'pos',
  BANK_TRANSFER = 'bank_transfer',
  CARD = 'card',
}

export const PAYMENT_METHOD_LABELS = {
  [PAYMENT_METHODS.CASH]: 'Cash',
  [PAYMENT_METHODS.POS]: 'POS',
  [PAYMENT_METHODS.BANK_TRANSFER]: 'Bank Transfer',
  [PAYMENT_METHODS.CARD]: 'Card',
};

export interface ITransaction {
  id: number;
  groupTag: string;
  amount: number;
  passengerId?: number;
  passenger?: IPassenger;
  travelScheduleId?: number;
  travelSchedule?: ITravelSchedule;
  ticketerId: number;
  ticketer?: IUser;
  type: TRANSACTION_TYPE;
  status: TRANSACTION_STATUS;
  paymentMethod: PAYMENT_METHODS;
  waybillId?: number;
  waybill?: IWaybill;
  processedAt?: Date | string;
  reversedAt?: Date;
  cancelledAt?: Date;
  serviceCharge: number;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface IDashboardTransactionsCount {
  rows: ITransaction[];
  count?: number;
}

export interface ITransactionAnalysis {
  cashTotal: number;
  cashSettlement: number;
  transferTotal: number;
  transferSettlement: number;
  posTotal: number;
  posSettlement: number;
}
