import { Button, Modal, Form, Row, Col, Select, DatePicker, TimePicker, Input } from 'antd';
import React, { useState } from 'react';

import 'src/screens/travel-schedule/new-shedule-modal/styles/addScheduleModal.less';
import { IRoutesGroup } from 'src/types/route.type';
import { IVehicle, IVehicleClass } from 'src/types/vehicle.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITrip } from 'src/types/trip.type';
import { IDestination } from 'src/types/branch.type';
import { getTripExpectedCheckinTime } from 'src/utilities/trip-expected-time.utils';
import moment from 'moment';
import {
  onDestinationFilter,
  onRouteGroupFilter,
  onUserFilter,
  onVehicleClassFilter,
  onVehicleFilter,
} from 'src/utilities/helpers.utils';
import { IUser, USER_ROLE } from 'src/types/user.type';
import { ISeatMap } from 'src/types/seat-map.type';
import { getNumberList } from 'src/utilities/array.utils';
import { useGetDestinations } from 'src/hooks/useGetDestinations';
import { useGetVehicleClasses } from 'src/hooks/useGetVehicleClasses';
import { useGetRouteGroups } from 'src/hooks/useGetRouteGroups';
import { useGetVehicles } from 'src/hooks/useGetVehicles';
import { useGetUsers } from 'src/hooks/useGetUsers';
import { useGetSeatMaps } from 'src/hooks/useGetSeatMaps';
import { useCreateTripMutation, ICreateTrip } from 'src/graphql/mutations/create-trip';
import { useUpdateTripMutation, IUpdateTrip } from 'src/graphql/mutations/update-trip';
import { useGetDiscountsQuery } from 'src/graphql/queries/get-all-discounts';
import { DISCOUNT_SEARCH } from 'src/types/discount.type';
import { Discount } from 'src/graphql/generated/types';

export const ADD_TRIP_MODAL = 'ADD_TRIP_MODAL';
const { Option } = Select;

export interface AddTravelScheduleProps {
  show?: boolean;
  isEdit?: boolean;
  onClose?: () => void;
  onTripActionCompleted?: (trip: ICreateTrip | ITrip | IUpdateTrip) => void;
  initialTrip?: ITrip;
}

export const TripModal: React.FC<AddTravelScheduleProps> = ({
  show,
  isEdit,
  onClose,
  onTripActionCompleted,
  initialTrip,
}) => {
  const { activeBranch } = useBranch();
  const [form] = Form.useForm();
  const maximumCapacityValue = Form.useWatch('maximumCapacity', form);

  const [checkinTime, setCheckinTime] = useState('');
  const [{ data: destinations = [], loading: loadingDestinations }] = useGetDestinations();
  const [{ data: vehicleClasses = [], loading: loadingVehicleClasses }] = useGetVehicleClasses();
  const [createTrip, { loading: createTripLoading }] = useCreateTripMutation();
  const [updateTrip, { loading: updateTripLoading }] = useUpdateTripMutation();
  const [{ data: routeGroups = [], loading: loadingRouteGroups }] = useGetRouteGroups({
    params: { branchId: activeBranch?.id! },
  });
  const [{ data: drivers = [], loading: loadingDrivers }] = useGetUsers({
    params: { branchId: activeBranch?.id!, roles: [USER_ROLE.FleetManagement] },
  });
  const [{ data: vehicles, loading: loadingVehicles }] = useGetVehicles({ params: { offset: 1000 } });

  const [{ data: seatMaps, loading: loadingSeatMaps }] = useGetSeatMaps();

  const { data: discounts, loading: discountLoading } = useGetDiscountsQuery(1, 100, 100, DISCOUNT_SEARCH.ACTIVE, true);

  const loading =
    loadingDestinations ||
    loadingVehicleClasses ||
    loadingRouteGroups ||
    loadingVehicles ||
    loadingDrivers ||
    createTripLoading ||
    updateTripLoading ||
    discountLoading ||
    loadingSeatMaps;

  const handleSave = async (payload: any) => {
    let result;
    const date = payload.date.format('YYYY-MM-DD');
    const time = payload.time.format('HH:mm');
    const expectedCheckinTime =
      checkinTime === 'custom'
        ? `${payload.customDate.format('YYYY-MM-DD')} ${payload.customTime.format('HH:mm')}`
        : getTripExpectedCheckinTime(date, time, checkinTime);
    const maximumCapacity = +maximumCapacityValue;
    let params = { ...payload, date, time, expectedCheckinTime, maximumCapacity };

    if (checkinTime === 'custom') {
      const { customDate, customTime, ...rest } = params;
      params = rest;
    }

    if (isEdit && initialTrip) {
      const response = await updateTrip({
        variables: {
          tripId: initialTrip.id,
          input: params,
        },
      });
      result = response.data?.updateTrip;
    } else {
      const response = await createTrip({
        variables: {
          input: params,
        },
      });
      result = response.data?.createTrip;
    }

    if (result && onTripActionCompleted) {
      onTripActionCompleted(result);
      form.resetFields();
    }
  };

  const destinationOptions = destinations?.map((destination: IDestination) => (
    <Option key={destination.id} value={destination.id}>
      {`${destination.name}`}
    </Option>
  ));

  const vehicleClassOptions = vehicleClasses?.map((vclass: IVehicleClass) => (
    <Option key={vclass.id} value={vclass.id}>
      {vclass.name}
    </Option>
  ));

  const vehiclesOptions = vehicles?.items?.map((vehicle: IVehicle) => (
    <Option key={vehicle.id} value={vehicle.id}>
      {vehicle.make} ({vehicle.registeredNumber})
    </Option>
  ));

  const discountOptions = discounts?.findDiscounts.items?.map((discount: Discount) => (
    <Option key={discount.id} value={discount.id}>
      {`${discount.description}`}
    </Option>
  ));

  const routeGroupOptions = routeGroups?.map((group: IRoutesGroup) => (
    <Option key={group.id} value={group.id}>
      {group.name}
    </Option>
  ));

  const driversOptions = drivers?.map((driver: IUser) => (
    <Option key={driver.id} value={driver.id}>
      {`${driver.firstName} ${driver.lastName}`}
    </Option>
  ));

  const seatMapOptions = seatMaps?.map((seatMap: ISeatMap) => (
    <Option key={seatMap.id} value={seatMap.id}>
      {seatMap.name !== null ? seatMap.name : `${seatMap.seatsCount} Seater`}
    </Option>
  ));

  const handleCancel = () => {
    form.resetFields();
    if (onClose) {
      onClose();
    }
  };

  let initialFormValues = {
    branchId: activeBranch?.id,
    date: moment(),
    time: moment(),
  };

  if (initialTrip) {
    const dateTime = moment(`${initialTrip.date} ${initialTrip.time}`);
    initialFormValues = {
      ...initialFormValues,
      ...initialTrip,
      date: dateTime,
      time: dateTime,
    };
  }

  const onDestinationSearch = (input: string, option?: { value: number }) => {
    return onDestinationFilter(destinations, input, option);
  };

  const onVehicleClassSearch = (input: string, option?: { value: number }) => {
    return onVehicleClassFilter(vehicleClasses, input, option);
  };

  const onVehicleSearch = (input: string, option?: { value: number }) => {
    return onVehicleFilter(vehicles!.items, input, option);
  };

  const onRouteGroupSearch = (input: string, option?: { value: number }) => {
    return onRouteGroupFilter(routeGroups, input, option);
  };

  const onDriverSearch = (input: string, option?: { value: number }) => {
    return onUserFilter(drivers, input, option);
  };

  const onCheckinTimeOptionSelected = (selected: string) => {
    setCheckinTime(selected);
  };

  const virtualList = getNumberList(20);

  return (
    <Modal
      title={isEdit ? 'Update Trip' : 'Add Trip'}
      open={show}
      closable={!loading}
      maskClosable={loading}
      onCancel={handleCancel}
      data-testid={ADD_TRIP_MODAL}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      <Form autoComplete="on" form={form} layout="vertical" initialValues={initialFormValues} onFinish={handleSave}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item label="Departure Terminal">
              <Input value={activeBranch?.name} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Final Destination"
              name="destinationId"
              rules={[{ required: true, message: 'Please select a destination' }]}
            >
              <Select showSearch placeholder="Select destination" filterOption={onDestinationSearch}>
                {destinationOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              label="Time"
              name="time"
              rules={[{ required: true, message: 'Enter a schedule time' }]}
              className="w-100"
            >
              <TimePicker placeholder="Select Time" use12Hours format="hh:mm a" className="w-100" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Select date" name="date" rules={[{ required: true, message: 'Enter a schedule date' }]}>
              <DatePicker placeholder="Select Date" format="YYYY-MM-DD" className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              name="maximumCapacity"
              label="Maximum Capacity"
              rules={[{ required: true, message: 'Enter maximum capcity' }]}
            >
              <Input type="number" min={1} placeholder="Max capacity" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="expectedCheckinTime"
              label="Expected Checkin Time"
              rules={[{ required: true, message: 'Please select the expected checkin time for the trip' }]}
            >
              <Select placeholder="Select expected checkin time" onSelect={onCheckinTimeOptionSelected}>
                <Option value="3 hours">3 hours before departure</Option>
                <Option value="6 hours">6 hours before departure</Option>
                <Option value="9 hours">9 hours before departure</Option>
                <Option value="12 hours">12 hours before departure</Option>
                <Option value="24 hours">24 hours before departure</Option>
                <Option value="immediately available">Immediately available</Option>
                <Option value="custom">Custom time</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {checkinTime === 'custom' && (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                label="Custom time"
                name="customTime"
                rules={[{ required: true, message: 'Enter a custom time' }]}
                className="w-100"
              >
                <TimePicker placeholder="Select Time" use12Hours format="hh:mm a" className="w-100" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Select Custom Date"
                name="customDate"
                rules={[{ required: true, message: 'Enter a custom date' }]}
              >
                <DatePicker placeholder="Select Custom Date" format="YYYY-MM-DD" className="w-100" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item
              label="Vehicle Class"
              name="vehicleClassId"
              rules={[{ required: true, message: 'Please select a vehicle class' }]}
            >
              <Select showSearch placeholder="Select vehicle class" filterOption={onVehicleClassSearch}>
                {vehicleClassOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Route Group"
              name="routeGroupId"
              rules={[{ required: true, message: 'Please select a route group' }]}
            >
              <Select showSearch placeholder="Select route group" filterOption={onRouteGroupSearch}>
                {routeGroupOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {isEdit ? (
            <Col span={12}>
              {initialTrip && !initialTrip?.sourceSeatMapId ? (
                <Form.Item label="Seat Map (optional)" name="sourceSeatMapId">
                  <Select showSearch placeholder="Select seat map for trip" allowClear>
                    {seatMapOptions}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
          ) : (
            <Col span={12}>
              <Form.Item
                label={checkinTime === 'immediately available' ? 'Seat Map ' : 'Seat Map (optional)'}
                name="sourceSeatMapId"
                rules={[
                  {
                    required: checkinTime === 'immediately available',
                    message: 'Please select a seat map',
                  },
                ]}
              >
                <Select showSearch placeholder="Select seat map for trip" allowClear>
                  {seatMapOptions}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <Form.Item
              label="Vehicle No."
              name="virtualVehicleNumber"
              rules={[{ required: true, message: 'Please provide a virtual vehicle number' }]}
            >
              <Select placeholder="Select a vehicle number" allowClear>
                {virtualList.map((n) => (
                  <Option key={`${n}`} value={`${n}`}>
                    {n}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Form.Item label="Vehicle (optional)" name="vehicleId">
              <Select showSearch placeholder="Select vehicle" filterOption={onVehicleSearch} allowClear>
                {vehiclesOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Driver (optional)" name="driverId">
              <Select showSearch placeholder="Select driver" filterOption={onDriverSearch} allowClear>
                {driversOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="discountIds"
              label="Discounts"
              initialValue={isEdit && initialTrip ? (initialTrip.discounts?.map((item) => item.id) as string[]) : []}
            >
              <Select
                loading={discountLoading}
                mode="multiple"
                allowClear
                id="discountIds"
                showSearch
                placeholder="Select a discount"
              >
                {discountOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
