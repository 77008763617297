/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Button, Table, Card, message, Space, Switch, Form, Input, Select, Collapse, Col } from 'antd';
import { PlusOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import 'src/screens/discounts/styles/discounts.styles.less';
import { usePage } from 'src/components/page/Page';
import { RBACContainer } from 'src/components/RBAC/RBACContainer';
import { Link } from 'react-router-dom';
import { USER_ROLE } from 'src/types/user.type';
import { DiscountModal } from 'src/screens/discounts/discount-modal/DiscountModal';
import { useGetDiscountsQuery, Discount } from 'src/graphql/queries/get-all-discounts';
import { DISCOUNT_SEARCH } from 'src/types/discount.type';
import { useUpdateDiscountMutation } from 'src/graphql/mutations/update-discount';

import { useAuth } from 'src/providers/auth-provider/AuthProvider';

const { Panel } = Collapse;

export const Discounts = () => {
  const page = usePage();

  const { user } = useAuth();

  const [updateDiscount] = useUpdateDiscountMutation();

  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [selectedDiscount, setSelectedDiscount] = useState<Discount | undefined>(undefined);
  const [searchField, setSearchField] = useState<string>('');
  const [searchType, setSearchType] = useState<DISCOUNT_SEARCH | undefined>(undefined);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [fetchData, setFetchData] = useState(false);

  const offset = 10;
  const { data, loading, refetch } = useGetDiscountsQuery(currentPage, pageSize, offset, undefined);

  const discounts = data?.findDiscounts?.items;

  useEffect(() => {
    page.setTitle('Discounts');
    page.setSubTitle('All Discounts');
  }, []);

  useEffect(() => {
    if (fetchData) {
      const isBooleanSearch = searchType && [DISCOUNT_SEARCH.ACTIVE, DISCOUNT_SEARCH.ONLINE, DISCOUNT_SEARCH.OFFLINE].includes(searchType);
      const parsedField = isBooleanSearch && (searchField.toLowerCase() === 'true' || searchField.toLowerCase() === 'false')
        ? JSON.parse(searchField.toLowerCase())
        : searchField;

      if (parsedField !== searchField || !isBooleanSearch) {
        refetch({
          pagination: { page: currentPage, limit: pageSize, offset },
          input: searchField && searchType ? { [searchType]: parsedField } : {},
        });
      } else {
        message.warning('Invalid input. Please enter true or false for this filter.', 4);
      }

      setFetchData(false);
    }
  }, [fetchData, currentPage, pageSize, refetch]);

  const showEditDiscountModal = (id: string) => {
    setIsEdit(true);
    const discount = discounts?.find((d) => d.id === id);
    setSelectedDiscount(discount);
    setShowDiscountModal(true);
  };

  const showAddDiscountModal = () => {
    setSelectedDiscount(undefined);
    setShowDiscountModal(true);
    setIsEdit(false);
  };

  const closeDiscountModal = () => {
    setSelectedDiscount(undefined);
    setShowDiscountModal(false);
    setIsEdit(false);
  };

  const onDiscountStatusChange = async (
    discountId: string,
    statusType: 'isActive' | 'isOnline' | 'isOffline',
    newValue: boolean,
    currentDiscount: { isActive: boolean; isOnline: boolean; isOffline: boolean },
  ) => {
    const updatedDiscount = await updateDiscount({
      variables: {
        discountId,
        input: {
          isActive: currentDiscount.isActive,
          isOnline: currentDiscount.isOnline,
          isOffline: currentDiscount.isOffline,
          [statusType]: newValue,
        },
      },
    });

    if (updatedDiscount) {
      message.success(`Discount ${statusType} status updated`);
    }
  };

  const onActionCompleted = async () => {
    await refetch();
    message.success(`Discount successfully ${selectedDiscount ? 'updated' : 'created'}`);
    setShowDiscountModal(false);
    setIsEdit(false);
  };

  const handlePaginationChange = (pageNum: number, size?: number) => {
    setCurrentPage(pageNum);
    if (size) setPageSize(size);
    setFetchData(true);
  };

  const columns: ColumnsType<Discount> = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => `${amount}`,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value: string | undefined) => value || 'N/A',
      align: 'center',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (value: string | undefined) => value || 'N/A',
      align: 'center',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (value: string | undefined) => value || 'N/A',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean, { id, isOnline, isOffline }) => (
        <Switch
          checked={isActive}
          onChange={(checked: boolean) =>
            onDiscountStatusChange(id, 'isActive', checked, {
              isActive,
              isOnline,
              isOffline,
            })}
        />
      ),
    },
    {
      title: 'Online',
      dataIndex: 'isOnline',
      key: 'isOnline',
      render: (isOnline: boolean, { id, isActive, isOffline }) => (
        <Switch
          checked={isOnline}
          onChange={(checked: boolean) =>
            onDiscountStatusChange(id, 'isOnline', checked, {
              isActive,
              isOnline,
              isOffline,
            })}
        />
      ),
    },
    {
      title: 'Offline',
      dataIndex: 'isOffline',
      key: 'isOffline',
      render: (isOffline: boolean, { id, isActive, isOnline }) => (
        <Switch
          checked={isOffline}
          onChange={(checked: boolean) =>
            onDiscountStatusChange(id, 'isOffline', checked, {
              isActive,
              isOnline,
              isOffline,
            })}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '150px',
      render: (id: string) => (
        <Space split="|">
          <Link to={`${id}`}>
            <Button className="more-btn" icon={<EyeOutlined />} />
          </Link>
          <Button onClick={() => showEditDiscountModal(id)} className="more-btn" icon={<EditOutlined />} />
        </Space>
      ),
    },
  ];

  const handleDiscountSearch = () => {
    if (searchField && searchType) {
      setCurrentPage(1);
      setPageSize(10);
      setFetchData(true);
    }
  };

  const handleClearDiscountSearch = () => {
    setSearchField('');
    setSearchType(undefined);
    refetch({
      pagination: { page: currentPage, limit: pageSize, offset },
      input: {},
    });
    setFetchData(false);
  };

  const handleSearchTypeChange = (value: DISCOUNT_SEARCH) => {
    setSearchType(value);
    setSearchField('');
  };

  return (
    <div className="discount-page-container">
      <RBACContainer roles={[USER_ROLE.Admin]} renderFailed={!user?.roles?.includes(USER_ROLE.Admin)}>
        <Card bordered={false}>
          <div className="extra-container" style={{ display: 'block', marginBottom: '16px' }}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              style={{ background: 'rgba(0, 0, 0, 0.02)', width: '100%' }}
            >
              <Panel header="Search Discounts" key="1">
                <Form layout="inline">
                  <Col span={4}>
                    <Form.Item label="Filter Discounts By" colon={false}>
                      <Select
                        placeholder="Select search filter"
                        onChange={handleSearchTypeChange}
                        value={searchType}
                      >
                        <Select.Option value={DISCOUNT_SEARCH.CODE}>Filter by Discount Code</Select.Option>
                        <Select.Option value={DISCOUNT_SEARCH.TYPE}>Filter by Discount Type</Select.Option>
                        <Select.Option value={DISCOUNT_SEARCH.ACTIVE}>Filter by Active Status</Select.Option>
                        <Select.Option value={DISCOUNT_SEARCH.ONLINE}>Filter by Online Status</Select.Option>
                        <Select.Option value={DISCOUNT_SEARCH.OFFLINE}>Filter by Offline Status</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Search Value" colon={false}>
                      <Input
                        placeholder={
                          searchType && [DISCOUNT_SEARCH.ACTIVE, DISCOUNT_SEARCH.ONLINE, DISCOUNT_SEARCH.OFFLINE].includes(searchType)
                            ? 'Enter true or false for the filter'
                            : 'Enter discount information'
                        }
                        value={searchField}
                        onChange={(e) => setSearchField(e.target.value)}
                      />
                    </Form.Item>

                  </Col>
                  <Col span={4}>
                    <Form.Item label=" " colon={false}>
                      <Space split="|">
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={handleDiscountSearch}
                          disabled={!searchField || !searchType}
                        >
                          Search Discount
                        </Button>
                        <Button
                          type="primary"
                          htmlType="reset"
                          onClick={handleClearDiscountSearch}
                          disabled={!(searchField || searchType)}
                        >
                          Clear Discount Filter
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Form>
              </Panel>
            </Collapse>

            <Button
              key="add-new-discount"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => showAddDiscountModal()}
              style={{ marginTop: '35px', marginBottom: '25px' }}
            >
              Add New
            </Button>
          </div>

          <Table
            rowKey="id"
            columns={columns}
            dataSource={discounts}
            loading={loading}
            size="small"
            bordered
            pagination={{
              onChange: handlePaginationChange,
              pageSize: data?.findDiscounts.meta.offset ?? 10,
              total: data?.findDiscounts.meta.totalItems || 0,
              current: data?.findDiscounts.meta.page,
            }}
            scroll={{ x: 500 }}
          />
        </Card>
      </RBACContainer>

      {showDiscountModal && (
        <DiscountModal
          show={showDiscountModal}
          onDiscountActionCompleted={onActionCompleted}
          onClose={closeDiscountModal}
          initialDiscount={selectedDiscount}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};
